import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { getCMSPageDataByContentType } from 'src/cms/client'
import RenderPageSections from 'src/components/cms/RenderedPageSections'

interface CmsData {
  sections: Array<{
    data: unknown
    id: string
    name: string
  }>
  status: string
}

interface Props {
  serverData: {
    cmsData?: CmsData
  }
}

export default function CampaignPage(props: Props) {
  const { cmsData } = props.serverData

  return (
    <>
      <GatsbySeo
        title=""
        description=""
        titleTemplate=""
        language=""
        canonical=""
        openGraph={{
          type: 'website',
          url: '',
          title: '',
          description: '',
        }}
      />

      <RenderPageSections sections={cmsData?.sections} />
    </>
  )
}

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const cmsData = await getCMSPageDataByContentType('institucional', {
    filters: { name: slug },
  })

  return {
    status: 200,
    props: {
      cmsData,
    },
    headers: {
      'cache-control': 'no-cache',
    },
  }
}
